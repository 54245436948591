import { useEffect } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import Header from '../components/header';
import top from '../assets/top.png';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import useLanguage from '../useLanguage';

const HomePage = () => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { language }= useLanguage();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        i18n.changeLanguage(language);
    }, []);

    return (
        <div
            style={{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4)), url(${top})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Header />
            <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', mt: isMobile? 15 : 20, ml: isMobile? 1 : 0 }}>
                <h1 style={{ color: 'white', fontSize: '3.2rem' }}>Reband brings DX beside You.</h1>
                {i18n.language === 'ja' && (
                    <span style={{ color: 'white', fontSize: '1.2rem' }}>デジタルトランスフォーメーションでビジネスを次のステージへ。</span>
                )}
            </Box>
        </div>
    );
};

export default HomePage;
