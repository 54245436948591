import React, { useEffect } from 'react';
import { Box, Divider, useMediaQuery } from '@mui/material';
import Header from '../components/header';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import useLanguage from '../useLanguage';

const CompanyInfoPage = () => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { language }= useLanguage();

    const formatText = (text: string) => {
        return text.split('\n').map((str, index) => (
            <React.Fragment key={index}>
                {str}
                <br />
            </React.Fragment>
        ));
    };

    useEffect(() => {
        i18n.changeLanguage(language);
    }, []);

    return (
        <div
            style={{
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'auto',
            }}
        >
            <Header textColor="#000000" backgroundColor="white"/>
            <Box sx={{ flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                mt: 12,
                ml: isMobile? 3 : 8,
                pt: '64px'
            }}>
                <h1 style={{ fontSize: isMobile? '2rem' : '4rem', marginBottom: '0.5rem' }}>About Us.</h1>
                {i18n.language === 'ja' && (
                    <h3 style={{ marginTop: '0' }}>会社情報</h3>
                )}
                {isMobile && 
                    <Divider sx={{ mt: 3, mb: 3, mr: 2 }} />
                }
                <Box sx={{ mt: isMobile? 1 : 6 }}>
                    <h1 style={{ fontSize: '1.8rem' }}>Vision & Mission.</h1>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: isMobile? '' : 'center', mt: isMobile? 2 : 9 }}>
                    <h1 style={{ fontSize: '1rem' }}>Vision.</h1>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: isMobile? '' : 'center', mt: 1 }}>
                    <span style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                        {formatText(t('Vision'))}
                    </span>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: isMobile? '' : 'center', mt: isMobile? 2 : 5 }}>
                    <h1 style={{ fontSize: '1rem' }}>Mission.</h1>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: isMobile? '' : 'center', mt: 1 }}>
                    <span style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                        {formatText(t('Mission'))}
                    </span>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: isMobile? '' : 'center', mt: 8 }}>
                    <span style={{ fontSize: '1.2rem' }}>
                        {formatText(t('Mission & Vision1'))}
                    </span>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: isMobile? '' : 'center', mt: 1 }}>
                    <span style={{ fontSize: '1.2rem' }}>
                        {formatText(t('Mission & Vision2'))}
                    </span>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: isMobile? '' : 'center', mt: 1 }}>
                    <span style={{ fontSize: '1.2rem' }}>
                        {formatText(t('Mission & Vision3'))}
                    </span>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: isMobile? '' : 'center', mt: 1 }}>
                    <span style={{ fontSize: '1.2rem' }}>
                        {formatText(t('Mission & Vision4'))}
                    </span>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: isMobile? '' : 'center', mt: 1 }}>
                    <span style={{ fontSize: '1.2rem' }}>
                        {formatText(t('Mission & Vision5'))}
                    </span>
                </Box>
                <Box sx={{ mt: 6 }}>
                    <h1 style={{ fontSize: '1.5rem' }}>Overview.</h1>
                </Box>
                <Divider sx={{ mt: 3, mb: 3, mr: isMobile? 2 : 20 }} />
                <Box sx={{ mt: isMobile? 0 : 3, display: isMobile? '' : 'flex', alignItems: 'center' }}>
                    <h1 style={{ fontSize: '1rem', width: '13rem' }}>{t('Company Label')}</h1>
                    <span style={{ fontSize: '1rem' }}>{t('Company')}</span>
                </Box>
                <Divider sx={{ mt: 3, mb: 3, mr: isMobile? 2 : 20 }} />
                <Box sx={{ mt: isMobile? 0 : 3, display: isMobile? '' : 'flex', alignItems: 'center' }}>
                    <h1 style={{ fontSize: '1rem', width: '13rem' }}>{t('Establishment Label')}</h1>
                    <span style={{ fontSize: '1rem' }}>{t('Establishment')}</span>
                </Box>
                <Divider sx={{ mt: 3, mb: 3, mr: isMobile? 2 : 20 }} />
                <Box sx={{ mt: isMobile? 0 : 3, display: isMobile? '' : 'flex', alignItems: 'center' }}>
                    <h1 style={{ fontSize: '1rem', width: '13rem' }}>{t('Capital Label')}</h1>
                    <span style={{ fontSize: '1rem' }}>{t('Capital')}</span>
                </Box>
                <Divider sx={{ mt: 3, mb: 3, mr: isMobile? 2 : 20 }} />
                <Box sx={{ mt: isMobile? 0 : 3, display: isMobile? '' : 'flex', alignItems: 'center' }}>
                    <h1 style={{ fontSize: '1rem', width: '13rem' }}>{t('Representative Label')}</h1>
                    <span style={{ fontSize: '1rem' }}>{t('Representative')}</span>
                </Box>
                <Divider sx={{ mt: 3, mb: 3, mr: isMobile? 2 : 20 }} />
                <Box sx={{ mt: isMobile? 0 : 3, display: isMobile? '' : 'flex', alignItems: 'center' }}>
                    <h1 style={{ fontSize: '1rem', width: '13rem' }}>{t('Employees Label')}</h1>
                    <span style={{ fontSize: '1rem' }}>{t('Employees')}</span>
                </Box>
                <Divider sx={{ mt: 3, mb: 3, mr: isMobile? 2 : 20 }} />
                <Box sx={{ mt: isMobile? 0 : 3, display: isMobile? '' : 'flex', alignItems: 'center' }}>
                    <h1 style={{ fontSize: '1rem', width: '13rem' }}>{t('Business content Label')}</h1>
                    <span style={{ fontSize: '1rem' }}>{formatText(t('Business content'))}</span>
                </Box>
                {/* <Divider sx={{ mt: 3, mb: 3, mr: isMobile? 2 : 20 }} />
                <Box sx={{ mt: isMobile? 0 : 3, display: isMobile? '' : 'flex', alignItems: 'center' }}>
                    <h1 style={{ fontSize: '1rem', width: '13rem' }}>{t('Location Label')}</h1>
                    <span style={{ fontSize: '1rem' }}>{t('Location')}</span>
                </Box> */}
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 9 }}/>
            </Box>
        </div>

    )
}

export default CompanyInfoPage;