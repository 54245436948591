import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

interface LoadingBackdropProps {
    isLoading: boolean;
}

const LoadingBackdrop: React.FC<LoadingBackdropProps> = ({ isLoading }) => {
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};

export default LoadingBackdrop;
