import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Link, Box, IconButton, Drawer, List, ListItem, ListItemText, useMediaQuery, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logo_black from '../assets/logo_black.png';
import logo_white from '../assets/logo_white.png';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import useLanguage from '../useLanguage';

interface HeaderProps {
    textColor?: string;
    backgroundColor?: string;
}

const Header: React.FC<HeaderProps> = ({ textColor = 'inherit', backgroundColor='transparent' }) => {
    const { t, i18n } = useTranslation();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { language, changeLanguage } = useLanguage();

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    const menuItems = [
        { text: t('company_info'), href: '/company' },
        { text: t('services'), href: '/service' , children: [
            { text: t('system_development'), href: '/system-development' },
            { text: t('app_development'), href: '/app-development' },
            { text: t('maintenance'), href: '/maintenance' },
            // { text: t('order_mate'), href: '/order-mate' },
        ] },
        { text: t('careers'), href: '/careers' },
        { text: t('news'), href: '/news' },
        { text: t('contact'), href: '/contact' },
    ];

    const handleLocaleChange = (locale: string) => {
        changeLanguage(locale);
    };

    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language, i18n]);
    
    return (
        <AppBar position="fixed" sx={{ backgroundColor: backgroundColor, boxShadow: 'none', zIndex: 10 }} enableColorOnDark>
            <Toolbar sx={{ backgroundColor: 'transparent', display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                <Link href="/" sx={{ display: 'flex', alignItems: 'center' }}>
                    {textColor === 'inherit' ?
                        (<img src={logo_white} alt="Reband Logo" width={100} height={30} />)
                        :
                        (<img src={logo_black} alt="Reband Logo" width={100} height={30} />)
                    }
                </Link>
                {isMobile ? (
                    <>
                        <IconButton edge="end" sx={{ color : textColor}} aria-label="menu" onClick={toggleDrawer(true)}>
                            <MenuIcon/>
                        </IconButton>
                        <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
                            <Box
                                sx={{ width: 250 }}
                                role="presentation"
                                onClick={toggleDrawer(false)}
                                onKeyDown={toggleDrawer(false)}
                            >
                                <List>
                                    {menuItems.map((item, index) => (
                                        <React.Fragment key={index}>
                                            <ListItem key={index} component="a" href={item.href}>
                                                <ListItemText primary={item.text} />
                                            </ListItem>
                                            {item.children && item.children.map((child, childIndex) => (
                                                <ListItem key={childIndex} component="a" href={child.href} sx={{ pl: 4 }}>
                                                    <ListItemText primary={child.text} />
                                                </ListItem>
                                            ))}
                                        </React.Fragment>
                                    ))}
                                </List>
                            </Box>
                            <Divider sx={{ mt: 9, mb: 3, mr: 2 }} />
                            <List sx={{ display: 'flex' }}>
                                <ListItem onClick={() => handleLocaleChange('ja')} sx={{ width: 'auto', paddingRight: 0 }}>
                                    <ListItemText primary={t('jp')} />
                                </ListItem>
                                <ListItem onClick={() => handleLocaleChange('en')} sx={{ width: 'auto', paddingLeft: 2 }}>
                                    <ListItemText primary={t('en')} />
                                </ListItem>
                            </List>
                        </Drawer>
                    </>
                ) : (
                    <>
                        <Box sx={{ display: 'flex', gap: 5, margin: 'auto' }}>
                            {menuItems.slice(0, 5).map((item, index) => (
                                <Link key={index} href={item.href} color={textColor} underline="none">
                                    {item.text}
                                </Link>
                            ))}
                        </Box>
                        <Box sx={{ display: 'flex', gap: 3, paddingRight: 6 }}>
                            <Link href="#" color={textColor} underline="none" onClick={() => handleLocaleChange('ja')}>
                                {t('jp')}
                            </Link>
                            <Link href="#" color={textColor} underline="none" onClick={() => handleLocaleChange('en')}>
                                {t('en')}
                            </Link>
                        </Box>
                    </>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default Header;