import React from 'react';
import { Box, Stepper, Step, StepLabel, Typography } from '@mui/material';


interface FormStepperProps {
    activeStep: number;
    steps: string[];
}

const FormStepper: React.FC<FormStepperProps> = ({ activeStep, steps }) => {
    return (
        <Box sx={{ width: '100%', mb: 5 }}>
            <Stepper
                activeStep={activeStep}
                alternativeLabel
                sx={{
                    '& .MuiStepIcon-root': {
                        width: 48,
                        height: 48,
                    },
                    '& .MuiStepIcon-root.Mui-active': {
                        color: '#45B6AF',
                    },
                    '& .MuiStepIcon-root.Mui-completed': {
                        color: '#45B6AF',
                    },
                }}
            >
                {steps.map((label, index) => (
                    <Step key={index}>
                        <StepLabel>{`${index + 1}. ${label}`}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
};

export default FormStepper;
