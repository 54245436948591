import React, { useState } from 'react';
import { Box, Button, TextField, Checkbox, FormControlLabel, Link, Typography, Divider, Container, Grid, FormHelperText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FormStepper from '../components/form-stepper';
import { useLoadingBackdrop } from '../hooks/useLoadingBackdrop';
import LoadingBackdrop from '../components/LoadingBackdrop';
import axios from 'axios';

interface ContactFormProps {
    isMobile?: boolean;
}

const ContactForm: React.FC<ContactFormProps> = ({ isMobile = false }) => {
    const { t } = useTranslation();
    const { isLoading, setIsLoading } = useLoadingBackdrop();
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        company_name: '',
        email: '',
        tell_no: '',
        message: '',
        is_policy: false
    });

    const [errors, setErrors] = useState({
        first_name: '',
        last_name: '',
        email: '',
        tell_no: '',
        message: '',
        is_policy: ''
    });
    const [activeStep, setActiveStep] = useState<number>(0);

    const handleChange = (e: any) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
        setErrors({
            ...errors,
            [name]: ''
        });
    };

    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validatePhone = (phone: string) => {
        const phoneRegex = /^\d{2,4}-\d{2,4}-\d{4}$/;
        return phoneRegex.test(phone);
    };

    const validateForm = () => {
        const newErrors: any = {};
        if (!formData.first_name) newErrors.first_name = t('errors.first_name_required');
        if (!formData.last_name) newErrors.last_name = t('errors.last_name_required');
        if (!formData.email) newErrors.email = t('errors.email_required');
        else if (!validateEmail(formData.email)) newErrors.email = t('errors.email_invalid');
        if (formData.tell_no && !validatePhone(formData.tell_no)) newErrors.tell_no = t('errors.phone_invalid');
        if (!formData.message) newErrors.message = t('errors.message_required');
        if (!formData.is_policy) newErrors.is_policy = t('errors.policy_required');
        return newErrors;
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const newErrors = validateForm();
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            setIsLoading(true);
            try {
                const API_URL = "https://auto-fix-connect-be.reband.co.jp";
                const response = await axios.post(
                    `${API_URL}/contact`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    },
                );

                console.log(response.data);
                setActiveStep(2);
            } catch (error) {
                console.error('Error submitting form:', error);
                alert("お問い合わせ送信に失敗しました。");
            } finally {
                setIsLoading(false);
            }
        }
    };

    const nextStep = (e: any) => {
        e.preventDefault();
        const newErrors = validateForm();
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            setActiveStep(1);
        }
    };

    const buckStep = () => {
        setActiveStep(activeStep - 1);
    };

    return (
        <Container maxWidth="md">
            <Box>
                <FormStepper
                    activeStep={activeStep}
                    steps={[t('Form Step Input'), t('Form Step Confirm'), t('Form Step Complete')]}
                />
                {(() => {
                    switch (activeStep) {
                        case 0:
                            // 入力画面
                            return (
                                <>
                                    <Grid container spacing={2}>
                                        <Grid item md={3}/>
                                        <Grid item md={9} sx={{ py: 2 }}>
                                            <span>{t('Contact Form Label')}</span>
                                        </Grid>
                                        <Grid item md={1}/>
                                        <Grid item md={11}>
                                            <h1 style={{ fontSize: '1rem', width: '13rem' }}>{t('Name Label')}</h1>
                                        </Grid>
                                        <Grid item md={1}/>
                                        <Grid item md={5.5}>
                                            <TextField 
                                                fullWidth 
                                                type="text" 
                                                name="last_name" 
                                                value={formData.last_name} 
                                                onChange={handleChange}
                                                placeholder={t('Last Name')}
                                                variant="outlined"
                                                margin="normal"
                                                sx={{width: isMobile? '120px' : '300px'}}
                                                error={Boolean(
                                                    errors.last_name,
                                                )}
                                                helperText={
                                                    errors.last_name
                                                }
                                            />
                                        </Grid>
                                        <Grid item md={5.5}>
                                            <TextField 
                                                fullWidth 
                                                type="text" 
                                                name="first_name" 
                                                value={formData.first_name} 
                                                onChange={handleChange} 
                                                placeholder={t('First Name')}
                                                variant="outlined"
                                                margin="normal"
                                                sx={{width: isMobile? '120px' : '300px'}}
                                                error={Boolean(
                                                    errors.first_name,
                                                )}
                                                helperText={
                                                    errors.first_name
                                                }
                                            />
                                        </Grid>
                                        <Grid item md={1}/>
                                        <Grid item md={11}>
                                            <h1 style={{ fontSize: '1rem', width: '13rem' }}>{t('Company Name Label')}</h1>
                                        </Grid>
                                        <Grid item md={1}/>
                                        <Grid item md={11}>
                                            <TextField 
                                                fullWidth 
                                                type="text" 
                                                name="company_name" 
                                                value={formData.company_name} 
                                                onChange={handleChange}
                                                placeholder={t('Company placeholder')}
                                                variant="outlined"
                                                margin="normal"
                                                sx={{width: isMobile? '280px' : "640px"}}
                                            />
                                        </Grid>
                                        <Grid item md={1}/>
                                        <Grid item md={11}>
                                            <h1 style={{ fontSize: '1rem', width: '13rem' }}>{t('Mail Address')}</h1>
                                        </Grid>
                                        <Grid item md={1}/>
                                        <Grid item md={11}>
                                            <TextField 
                                                fullWidth 
                                                type="text" 
                                                name="email" 
                                                value={formData.email} 
                                                onChange={handleChange}
                                                placeholder="xxx@example.co.jp" 
                                                variant="outlined"
                                                margin="normal"
                                                sx={{width: isMobile? '280px' : "640px"}}
                                                error={Boolean(
                                                    errors.email,
                                                )}
                                                helperText={
                                                    errors.email
                                                }
                                            />
                                        </Grid>
                                        <Grid item md={1}/>
                                        <Grid item md={11}>
                                            <h1 style={{ fontSize: '1rem', width: '13rem' }}>{t('Telephone')}</h1>
                                        </Grid>
                                        <Grid item md={1}/>
                                        <Grid item md={11}>
                                            <TextField 
                                                fullWidth 
                                                type="text" 
                                                name="tell_no" 
                                                value={formData.tell_no} 
                                                onChange={handleChange}
                                                placeholder="000-0000-0000" 
                                                variant="outlined"
                                                margin="normal"
                                                sx={{width: isMobile? '280px' : "640px"}}
                                                error={Boolean(
                                                    errors.tell_no,
                                                )}
                                                helperText={
                                                    errors.tell_no
                                                }
                                            />
                                        </Grid>
                                        <Grid item md={1}/>
                                        <Grid item md={11}>
                                            <h1 style={{ fontSize: '1rem', width: '13rem' }}>{t('Contact')}</h1>
                                        </Grid>
                                        <Grid item md={1}/>
                                        <Grid item md={11}>
                                            <TextField 
                                                fullWidth 
                                                name="message" 
                                                value={formData.message} 
                                                onChange={handleChange}
                                                placeholder={t('Contact placeholder')}
                                                variant="outlined"
                                                margin="normal"
                                                multiline
                                                sx={{width: isMobile? '280px' : "640px"}}
                                                rows={10}
                                                error={Boolean(
                                                    errors.message,
                                                )}
                                                helperText={
                                                    errors.message
                                                }
                                            />
                                        </Grid>
                                        <Grid item md={3.5}/>
                                        <Grid item md={8.5}>
                                            <FormControlLabel
                                                control={<Checkbox checked={formData.is_policy} onChange={handleChange} name="is_policy" />}
                                                label={<span><Link href="/policy" target="_blank" rel="noopener noreferrer">{t('Personal information Label 1')}</Link>{t('Personal information Label 2')}</span>}
                                            />
                                            {errors.is_policy && (
                                                <FormHelperText sx={{color: "#d32f2f"}}>
                                                    {errors.is_policy}
                                                </FormHelperText>
                                            )}
                                        </Grid>
                                        <Box sx={{ py: 6 }}/>
                                        <Grid item md={3.5}/>
                                        <Grid item md={8.5}>
                                            <Button variant="contained" 
                                                sx={{ 
                                                    borderRadius: '8px', 
                                                    backgroundColor: 'white', 
                                                    width: "200px",
                                                    color: 'black', 
                                                    fontSize: '1rem', 
                                                    padding: '20px 40px',
                                                    '&:hover': {
                                                        backgroundColor: '#f0f0f0',
                                                    }
                                                }}
                                                onClick={nextStep}>
                                                {t('Confirm Button')}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </>
                    );
                    case 1:
                        // 確認
                        return (
                            <>
                                <Grid container spacing={2} sx={{ py: 5 }}>
                                    <Grid md={3.5}/>
                                    <Grid md={8.5}>
                                        <span>{t('Contact Form Label Confirm')}</span>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={{ py: 2 }}>
                                    <Grid container spacing={2} md={12}>
                                        <Grid item md={1}/>
                                        <Grid item md={5}>
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {t('Name Label')}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6}>
                                            <span style={{ fontSize: '1rem' }}>{formData.last_name} {formData.first_name} </span>
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{ mt: 2, mb: 2, width: '100%',}}/>
                                </Grid>
                                <Grid container spacing={2} sx={{ py: 2 }}>
                                    <Grid container spacing={2} md={12}>
                                        <Grid item md={1}/>
                                        <Grid item md={5}>
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {t('Company Name Label')}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6}>
                                            <span style={{ fontSize: '1rem' }}>{formData.company_name}</span>
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{ mt: 2, mb: 2, width: '100%',}}/>
                                </Grid>
                                <Grid container spacing={2} sx={{ py: 2 }}>
                                    <Grid container spacing={2} md={12}>
                                        <Grid item md={1}/>
                                        <Grid item md={5}>
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {t('Mail Address')}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6}>
                                            <span style={{ fontSize: '1rem' }}>{formData.email}</span>
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{ mt: 2, mb: 2, width: '100%',}}/>
                                </Grid>
                                <Grid container spacing={2} sx={{ py: 2 }}>
                                    <Grid container spacing={2} md={12}>
                                        <Grid item md={1}/>
                                        <Grid item md={5}>
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {t('Telephone')}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6}>
                                            <span style={{ fontSize: '1rem' }}>{formData.tell_no}</span>
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{ mt: 2, mb: 2, width: '100%',}}/>
                                </Grid>
                                <Grid container spacing={2} sx={{ py: 2 }}>
                                    <Grid container spacing={2} md={12}>
                                        <Grid item md={1}/>
                                        <Grid item md={5}>
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {t('Contact')}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6}>
                                            <span style={{ fontSize: '1rem' }}>{formData.message}</span>
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{ mt: 2, mb: 2, width: '100%',}}/>
                                </Grid>
                                <Box
                                    sx={{
                                        textAlign: 'center',
                                        py: 5,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        gap: 4,
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        onClick={buckStep}
                                        sx={{
                                            borderRadius: '8px',
                                            backgroundColor: '#a9a9a9',
                                            fontSize: isMobile
                                                ? '1rem'
                                                : '1.25rem',
                                            color: 'black',
                                            padding: '10px 40px',
                                            textTransform: 'none',
                                            '&:hover': {
                                                backgroundColor:
                                                    '#c0c0c0',
                                            },
                                        }}
                                    >
                                        {t('Buck Button')}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        sx={{
                                            borderRadius: '8px',
                                            fontSize: isMobile
                                                ? '1rem'
                                                : '1.25rem',
                                            padding: '10px 40px',
                                            textTransform: 'none',
                                        }}
                                        onClick={handleSubmit}
                                    >
                                        {t('Send Button')}
                                    </Button>
                                </Box>
                            </>
                        );
                    case 2:
                        // 完了
                        return (
                            <>
                                <Grid container spacing={2} sx={{ py: 5 }}>
                                    <Grid md={0.5}/>
                                    <Grid md={11.5}>
                                        <Typography variant="h6" sx={{ textAlign: 'center', mb: 4 }}>
                                            {t('Contact Form Label Complete1')}
                                        </Typography>
                                        <Typography variant="body1" sx={{ textAlign: 'center', mb: 2 }}>
                                            {t('Contact Form Label Complete2')}
                                        </Typography>
                                        <Typography variant="body1" sx={{ textAlign: 'center', mb: 2 }}>
                                            {t('Contact Form Label Complete3')}
                                        </Typography>
                                        <Typography variant="body2" sx={{ textAlign: 'center', color: 'gray' }}>
                                            {t('Contact Form Label Complete4')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </>
                        );
                    }
                })()}
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 9 }}/>
            </Box>
            <LoadingBackdrop isLoading={isLoading} />
        </Container>
    );
};

export default ContactForm;