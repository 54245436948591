import React, { useState, useEffect } from 'react';
import { Box, Divider, useMediaQuery, Grid, Button } from '@mui/material';
import Header from '../components/header';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import useLanguage from '../useLanguage';
import { useNavigate } from 'react-router-dom';

const ServicePage = () => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { language }= useLanguage();
    const navigate = useNavigate();
    const [hoveredBox, setHoveredBox] = useState<String>('');

    const formatText = (text: string) => {
        return text.split('\n').map((str, index) => (
            <React.Fragment key={index}>
                {str}
                <br />
            </React.Fragment>
        ));
    };

    useEffect(() => {
        i18n.changeLanguage(language);
    }, []);

    return (
        <div
            style={{
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'auto',
            }}
        >
            <Header textColor="#000000" backgroundColor="white"/>
            <Box sx={{ flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                mt: 12,
                ml: isMobile? 3 : 8,
                pt: '64px'
            }}>
                <h1 style={{ fontSize: isMobile? '2rem' : '4rem', marginBottom: '0.5rem' }}>Service.</h1>
                {i18n.language === 'ja' && (
                    <h3 style={{ marginTop: '0' }}>サービス</h3>
                )}
                {isMobile && 
                    <Divider sx={{ mt: 3, mb: 3, mr: 2 }} />
                }
                <Grid container spacing={2} alignItems="flex-start">
                    <Grid item md={5}>
                        <Box sx={{ mt: isMobile? 0 : 3, display: isMobile? '' : 'flex', alignItems: 'center' }}>
                            <span style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                                {t('System App Development Support')}
                            </span>
                        </Box>
                        <Box sx={{ mt: 3, display: 'flex', alignItems: 'center' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{ 
                                    fontSize: '1rem', 
                                    padding: '28px 80px', 
                                    backgroundColor: '#45B6AF', 
                                    '&:hover': {
                                        backgroundColor: '#45B6AF',
                                    },
                                    '&:focus': {
                                        backgroundColor: '#45B6AF',
                                    }
                                }}
                                onClick={() => navigate('/contact')}
                            >
                                {t('Consult First')}
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item md={6}>
                        <Box sx={{ mt: isMobile ? 0 : 3, position: 'relative' }}>
                            <span style={{ fontSize: '1.2rem' }}>
                                {formatText(t('Serivce Description'))}
                            </span>
                        </Box>
                    </Grid>
                </Grid>
                <Divider sx={{ mt: 10, mb: 3, mr: isMobile? 2 : 10 }} />
                <span style={{ fontSize: '2rem' }}>
                    {t('DX Support')}
                </span>
                <Box sx={{ display: isMobile? '' : 'flex', alignItems: 'center' }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item md={3}/>
                        <Grid item md={9}>
                            <Box 
                                sx={{ mt: isMobile? 0 : 0, display: isMobile? '' : 'flex', alignItems: 'center', cursor: 'pointer' }}
                                onClick={() => navigate('/system-development')}
                                onMouseEnter={() => setHoveredBox('system-development')}
                                onMouseLeave={() => setHoveredBox('')}
                            >
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item md={8}>
                                        <span style={{ fontSize: '2rem', color: hoveredBox === 'system-development' ? '#45B6AF' : 'inherit' }}>{t('System Development')}</span>
                                    </Grid>
                                    {i18n.language === 'ja' && (
                                        <Grid item md={4}>
                                            <span style={{ fontSize: '1rem', color: hoveredBox === 'system-development' ? '#45B6AF' : 'inherit' }}>System Development</span>
                                        </Grid>
                                    )}
                                </Grid>
                            </Box>
                            <Divider sx={{ mt: 3, mb: 3, mr: isMobile? 2 : 14 }} />
                            <Box
                                sx={{ mt: isMobile? 0 : 0, display: isMobile? '' : 'flex', alignItems: 'center', cursor: 'pointer' }}
                                onClick={() => navigate('/app-development')}
                                onMouseEnter={() => setHoveredBox('app-development')}
                                onMouseLeave={() => setHoveredBox('')}
                            >
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item md={8}>
                                        <span style={{ fontSize: '2rem', color: hoveredBox === 'app-development' ? '#45B6AF' : 'inherit' }}>{t('App Development')}</span>
                                    </Grid>
                                    {i18n.language === 'ja' && (
                                        <Grid item md={4}>
                                            <span style={{ fontSize: '1rem', color: hoveredBox === 'app-development' ? '#45B6AF' : 'inherit' }}>App Development</span>
                                        </Grid>
                                    )}
                                </Grid>
                            </Box>
                            <Divider sx={{ mt: 3, mb: 3, mr: isMobile? 2 : 14 }} />
                            <Box
                                sx={{ mt: isMobile? 0 : 0, display: isMobile? '' : 'flex', alignItems: 'center', cursor: 'pointer' }}
                                onClick={() => navigate('/maintenance')}
                                onMouseEnter={() => setHoveredBox('maintenance')}
                                onMouseLeave={() => setHoveredBox('')}
                            >
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item md={8}>
                                        <span style={{ fontSize: '2rem', color: hoveredBox === 'maintenance' ? '#45B6AF' : 'inherit' }}>{t('Maintenance and Operations')}</span>
                                    </Grid>
                                    {i18n.language === 'ja' && (
                                        <Grid item md={4}>
                                            <span style={{ fontSize: '1rem', color: hoveredBox === 'maintenance' ? '#45B6AF' : 'inherit' }}>Maintenance</span>
                                        </Grid>
                                    )}
                                </Grid>
                            </Box>
                            <Divider sx={{ mt: 3, mb: 3, mr: isMobile? 2 : 14 }} />
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ mt: 5, mb: 3, mr: isMobile? 2 : 10 }} />
                {/* <span style={{ fontSize: '2rem' }}>
                    {t('Our Services')}
                </span>
                <Box sx={{ display: isMobile? '' : 'flex', alignItems: 'center' }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item md={3}/>
                        <Grid item md={9}>
                            <Box
                                sx={{ mt: isMobile? 0 : 0, display: isMobile? '' : 'flex', alignItems: 'center', cursor: 'pointer' }}
                                onClick={() => navigate('/order-mate')}
                                onMouseEnter={() => setHoveredBox('order-mate')}
                                onMouseLeave={() => setHoveredBox('')}
                            >
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item md={8}>
                                        <span style={{ fontSize: '2rem', color: hoveredBox === 'order-mate' ? '#45B6AF' : 'inherit' }}>{t('Restaurant App')}</span>
                                    </Grid>
                                    {i18n.language === 'ja' && (
                                        <Grid item md={4}>
                                            <span style={{ fontSize: '1rem', color: hoveredBox === 'order-mate' ? '#45B6AF' : 'inherit' }}>Order Mate</span>
                                        </Grid>
                                    )}
                                </Grid>
                            </Box>
                            <Divider sx={{ mt: 3, mb: 3, mr: isMobile? 2 : 14 }} />
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}/> */}
            </Box>
        </div>

    )
}

export default ServicePage;